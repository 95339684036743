import styled from "styled-components"
import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  img {
    transition: all 0.3s !important;
  }
`

export const Image = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  object-fit: none;
  border-radius: 9px;
  -webkit-box-reflect: below 0px
    linear-gradient(transparent, transparent, transparent, #0003);

  @media screen and (min-width: 450px) {
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    :hover {
      transform: perspective(800px) rotateY(0deg);
      opacity: 0.9;
      background: linear-gradient(145deg, #080606, #0a0707);
      box-shadow: 6px 6px 12px #040303, -6px -6px 12px #0e0b0b;
    }
  }

  @media (min-width: 768px) {
    width: 100%;
  }
`
export const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px 0px;

  @media (max-width: 992px) {
    padding: 20px;
  }
`

export const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

export const PageContent = styled.article`
  margin: 20px 0 20px 0;
  width: 100%;

  h1 {
    line-height: 80px;
    font-family: "Gobold", sans-serif;
    text-align: left;
    letter-spacing: 10px;
    font-size: 2.5em;
    text-align: right;
    @media (min-width: 992px) {
      font-size: 5em;
      letter-spacing: 20px;
    }
  }

  .productos-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 18px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 0 15px;

    @media screen and (min-width: 450px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .entry-content {
    padding: 10px;
    opacity: 0.6;
    transition: 0.5s all ease-in-out;
    margin-bottom: 18px;

    :hover {
      opacity: 1;
    }
  }
`

export const StyledH2 = styled.h2`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #fff;
  :hover {
    color: #60c1aa;
  }
`

export const StyledDate = styled.div`
  font-family: "Chub Gotic", sans-serif;
  font-size: 1rem;
  font-weight: 600;
`

export const StyledReadMore = styled(Link)`
  font-family: "Chub Gotic", sans-serif;
  font-size: 0.85rem;
  font-weight: 800;
  color: #000;
  position: relative;
  width: 120px;
  height: 35px;
  display: block;
  background: #fff;
  padding: 3px 0 0 10px;

  :hover {
    background: #ff6600;
    color: #fff;
  }
`
